import React from 'react';
import NextImage, { StaticImageData } from 'next/image';
import { Mail, Mobile, Person } from '@solent-university/solent-icons';

import type { Props as LinkProps } from 'Elements/Link/Link';

import defaultProfileImage from 'Public/images/cards/profile/default.png';

import {
    ProfileStyled,
    Inner,
    Information,
    Contacts,
    ProfileTitle,
    JobTitle,
    Info,
    Link,
    ContactInformation,
    StyledIcon,
    ImageWrapper,
    Wave
} from './Profile.styled';

export interface Props {
    honorific?: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    info: string;
    email?: LinkProps;
    phone?: LinkProps;
    profileLink: LinkProps;
    border?: boolean;
    image?: {
        src: StaticImageData | string;
        alt: string | undefined;
    };
}

const Profile: React.FC<Props> = props => {
    const {
        honorific,
        firstName,
        lastName,
        jobTitle,
        info,
        email,
        phone,
        profileLink,
        image,
        border = false
    } = props;

    return (
        <ProfileStyled $border={border}>
            <Wave />
            <Inner>
                <Information>
                    <ProfileTitle>
                        {honorific} {firstName} {lastName}
                    </ProfileTitle>
                    <JobTitle>{jobTitle}</JobTitle>
                    <Info>{info}</Info>
                    <Contacts>
                        {email && (
                            <ContactInformation>
                                <StyledIcon as={Mail} />
                                <Link {...email} openInNewTab={true} />
                            </ContactInformation>
                        )}
                        {phone && (
                            <ContactInformation>
                                <StyledIcon as={Mobile} />
                                <Link {...phone} openInNewTab={true} />
                            </ContactInformation>
                        )}
                        <ContactInformation>
                            <StyledIcon as={Person} />
                            <Link {...profileLink} />
                        </ContactInformation>
                    </Contacts>
                </Information>
                <Link {...profileLink} label={undefined}>
                    <ImageWrapper>
                        <NextImage
                            src={image?.src || defaultProfileImage}
                            alt={image?.alt || ''}
                            fill
                            style={{ objectFit: 'cover' }}
                        />
                    </ImageWrapper>
                </Link>
            </Inner>
        </ProfileStyled>
    );
};

export default Profile;
